body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.openStatusRow {
  background-color: #ffcccc;
}

h1 {
 font-size: 2em;
}

.ant-layout-sider-children h2 {
  text-align: center;
  padding-top: 1em;
}

.privateLayout {
  padding: 1em;
  width: 100%;
  border-left: 1px #ccc solid;
  background-color: white;
}

code.block {
  display: block;
  margin: 0 1em;
  background: #f2f4f5;
  padding: .4em .8em;
  border-radius: 3px;
  font-size: .9em;
  border: 1px solid #eee;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.blue-link {
  color: #1890ff;
  text-decoration: none;
}
.blue-link:hover,
.blue-link:focus {
  color: #3ea8ff;
}

.dropdown-link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.dropdown-link-button:hover,
.dropdown-link-button:focus {
  text-decoration: none;
}

